import React from 'react';
// import {show, hide} from "../customComponents/Notify";
import { store } from "../redux/store";
// var tinycolor = require("tinycolor2");



class Helper1{

  status = () => {
    return {0:'Awaiting approval', 1:'Active', 2:'Suspended'};
  }

}


// export const notify = (msg, code) => {
//     show(msg, code)
  
// }


export const guessAdminRoute = () => {

  if (process.env.NODE_ENV === 'production') {

    
    const currentUrl = window.location.href;
    const urlWithoutSubdomain = currentUrl.replace(/^(?:https?:\/\/)?(?:[^@\/]+@)?/, '');
    const urlWithoutWww = urlWithoutSubdomain.replace(/^www./, '');
    const newlastUrl = urlWithoutWww.split("/")[0]

    let detectSubDomain =  newlastUrl.split(".")
    let newAdminBase = detectSubDomain.length > 2?`${detectSubDomain[detectSubDomain.length-2]}.${detectSubDomain[detectSubDomain.length-1]}`:newlastUrl

    return `https://admin.${newAdminBase}` //all live admin (API) address must look like admin.example.com, this app can be located on main or subdomain of same domain address with the admin
      // return `https://stan.flara.me/` //all live admin (API) address must look like admin.example.com, this app can be located on main or subdomain of same domain address with the admin


  }else{

    return "http://localhost/jona10.2023.2/public";

  }

}


export const addUrlToPath = (path) => {

  return guessAdminRoute() + '/' + path;

}


export const padNumber = (padThisNumber, length = 4, filler = 0, maximumNumber = 9999) => {
    
    let numberToPad = String(padThisNumber)

    if(numberToPad > maximumNumber){
        return numberToPad
    }

    let newpad = numberToPad?.padStart(length, filler)

    return newpad
}




export const displayTime = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    // timeZoneName: 'short'
  };
  
  return new Intl.DateTimeFormat('en-US', options).format(date);

}

export const formatTime = (dateString) =>  {
  const date = new Date(dateString);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
}



export const formatDate = (date) => {
  var d = new Date(date);
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var day = d.getDate();

  var currentYear = new Date().getFullYear();
  var formattedDate = padNumber(month, 2) + "/" + padNumber(day, 2);

  if (year !== currentYear) {
    formattedDate += "/" + year;
  }

  return formattedDate;
}


export const niceDate = (paramObj) => {
  const dateObj = new Date(paramObj);
  return dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1) + '-' + dateObj.getDate();
}


export const decimalCount = num => {
    const numStr = String(num)
    if(numStr.includes('.')){
        return numStr.split('.')[1].length;
    }

    return 0
}


const getMother = () => store.getState()?.settings?.settings?.plans;

export const money = (myAmount, currency, naira = false, addObj={}) => {

  const motherObj = getMother()?.filter((item, index) => item.mother.type == Number(currency))
  const selectedMother = motherObj?.[0]?.mother ?? '';
  var nf = new Intl.NumberFormat("en-US", { minimumFractionDigits: addObj?.decimals ?? selectedMother?.decimals ?? 2 } );
  let syn = selectedMother?.symbol ?? '';
  // let syn = '';

  let amount = nf.format(myAmount);

  if(selectedMother?.symbollocation == 0){
    return syn + amount
  }else if(selectedMother?.symbollocation == 1){
    return amount + syn
  }

  return amount;
  

}


export const FormatOnly = (amount) => {
  var nf = new Intl.NumberFormat();
  return (nf.format(amount))
}



// export const customModalStyles = {
//       content: {
//             position: 'absolute',
//             bottom: 0,
//             border: '0px solid #ccc',
//             background: footer,
//             borderRadius: '30px 30px 0 0',
//             outline: 'none',
//             padding: '20px',
//             color: '#fff',
//             marginBottom: 0,
//             width: '100%',
//             transition: '0.5s',
//       },
//       overlay: {
//         position: 'fixed',
//         left: 0,
//         right: 0,
//         bottom: 0,
//         zIndex: 99999999,
//         backgroundColor: 'rgba(0, 0, 0, 0.6)',
//         padding: 0,
//         margin: 0,
//       }
//     };



// export const customNormalModalStyles = {
//   content: {
//     top: '40%',
//     left: '50%',
//     // right: '75%',
//     width: '400px',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     background: 'transparent',
//     zIndex: 999999,
    
//   },

//   overlay: {
//         position: 'fixed',
//         left: 0,
//         right: 0,
//         bottom: 0,
//         zIndex: 99999999,
//         background:  'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))',
//         padding: 0,
//         margin: 0,
//       }
// };




// export const balanceSettings = {
//         dots: false,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         autoplay: false,

//         responsive: [{
//             breakpoint: 600,
//             settings: {
//               slidesToShow: 1,
//               slidesToScroll: 1,
//               initialSlide: 1
//             }
//         }]
//     };





// export const currencySliderArray = (refToUse2, refToUse1, financeArray) => {
//         let arrowd;

//         arrowd = (financeArray ?? [1])?.length > 1 && <div style={{marginRight: 20}}>
//             <BsArrowLeftSquare onClick={() => 
//                     {   
//                         if(refToUse2){
//                             refToUse2.current.slickPrev(); 
//                         }
//                         if(refToUse1){
//                             refToUse1.current.slickPrev()
//                         }
//                     }
//                 } className="cursor-pointer text-info-1" size={20}/> {" "}
//             <BsArrowRightSquare onClick={() => 
//                 {
//                     if(refToUse2){
//                         refToUse2.current.slickNext(); 
//                     }
//                     if(refToUse1){
//                         refToUse1.current.slickNext()
//                     }

//                 } 

//             } className="cursor-pointer text-info-1" size={20}/>
//         </div>

//         return arrowd;
//     }


  export const moneyStatus = () => {
    return ['Pending', 'Processing', 'Confirmed', 'Cancelled'];
  }


export const usefulColors = (code) => {
    let array = ['danger', 'success', 'info', 'warning'];
    return array[code] ?? 'warning';
}


export const randColors = [
   "#46c7b1", "#ffbc02", "#262734", "#8644f6", "#375d80", "#79e4fe", "#baeaac", "#182bd2", "#20fd4d", "#6762e0", "#482e4a", "#fc5640", "#43fa05", "#3cde14", "#37a776", "#3d41ac", "#0dc19a", "#c5e011", "#230eaa", "#d24daa", "#9faa91", "#0f2c13", "#7a429c", "#47505f", "#FFA500", "#FFA500", "#ea9e70", "#63b598", "#c6e1e8", "#a48a9e", "#c6e1e8", "#648177" ,"#0d5ac1" , "#f205e6" ,"#1c0365" ,"#14a9ad" ,"#4ca2f9"
];


// export const hexToRgba = (hex) => {

//     var color = tinycolor(hex);
//     let conted = color.toRgb(); // { r: 255, g: 0, b: 0, a: 1 }

//   let r = conted?.r;
//   let g = conted?.g;
//   let b = conted?.b;
//   return `${r}, ${g}, ${b}`;
// }




export const byNextDate = (arr) => {
  let now = new Date();
  return arr?.filter(obj => new Date(obj.fixture_datetime) >= now).sort((a, b) => {
    return Math.abs(new Date(a.fixture_datetime) - now) -
           Math.abs(new Date(b.fixture_datetime) - now);
  });
}



export const byToDaysDate = (arr) => {

  var today = new Date();
  today.setHours(0, 0, 0, 0);

  var filteredArray = arr?.filter(function(obj) {
    var date = new Date(obj.fixture_datetime);
    return date >= today && date < new Date(today.getTime() + 86400000);
  });



  let now = new Date();
  return filteredArray?.filter(obj => new Date(obj.fixture_datetime) >= now).sort((a, b) => {
    return Math.abs(new Date(a.fixture_datetime) - now) -
           Math.abs(new Date(b.fixture_datetime) - now);
  });
}





export const fixtureStatuses = () => {
  return [
    ['CANC', 'Cancelled'],
    ['TBD', 'Time To Be Defined'],
    ['NS', 'Not Started'],
    ['1H', 'First Half, Kick Off'],
    ['HT', 'Halftime'],
    ['2H', 'Second Half, 2nd Half Started'],
    ['ET', 'Extra Time'],
    ['BT', 'Break Time'],
    ['P', 'Penalty In Progress'],
    ['SUSP', 'Match Suspended'],
    ['INT', 'Match Interrupted'],
    ['FT', 'Match Finished'],
    ['AET', 'Match Finished After Extra Time'],
    ['PEN', 'Match Finished After Penalty'],
    ['PST', 'Match Postponed'],
    ['CANC', 'Match Cancelled'],
    ['ABD', 'Match Abandoned'],
    ['AWD', 'Technical Loss'],
    ['WO', 'WalkOver'],
    ['LIVE', 'In Progress']
  ];
}






export default Helper1;