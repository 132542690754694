import React, { useState, useEffect } from "react";
import './assets/css/tailwind.css';
import './assets/scss/tailwind.scss';

import './assets/scss/icons.scss';

import {
  Route,
  Routes
} from "react-router-dom";


// import Index from "./pages/index.js";
// import IndexTwo from "./pages/index-two.js";
// import IndexThree from "./pages/index-three.js";

// import IndexFour from "./pages/index-four.js";
// import IndexFive from "./pages/index-five.js";
// import IndexSix from "./pages/index-six.js";
// import IndexSeven from "./pages/index-seven.js";
import IndexEight from "./pages/index-eight.js";
// import Login from './pages/auth/login';
// import Signup from './pages/auth/signup';
// import ResetPassword from './pages/auth/reset-password';
// import Loader from "./component/Loader";
import Loader from './component/loader/index';


import { allRemoteSettingsSelect } from './redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from './redux/actions';



export default function App() {



  const dispatch = useDispatch();

  const site_settings = useSelector(allRemoteSettingsSelect);

  

  const [masterIsLoading, setMasterIsLoading] = useState(false);


  const [loading, setLoading] = useState(site_settings?.site_settings?false:true);









    useEffect(() => {
        dispatch(authActions.onRefreshSystemData("settings/bootstrap/information", 10, {}, response => {
            // setLoading(false)
            setLoading(false);
        }))
    }, [])











  useEffect(() => {
    document.documentElement.setAttribute('dir', 'ltr');
    handleRouteChange();
  }, []);


  // Route change method
  const handleRouteChange = () => {
    // setLoading(false);
  }

  return (
    <>
      {loading && <Loader />}
      {!loading && <Routes>
        <Route path="/" element={<IndexEight />} />
      </Routes>}
    </>
  );

}
