import React, {Component} from "react";


    

function JivoChatWidget(props){

  React.useEffect(() => {
      const script = document.createElement("script");
      script.src = `//code.jivosite.com/widget/${props.widgetId}`;
      script.async = true;
      document.body.appendChild(script);
  }, [])

  return <div id="jivochat-widget" />;

}

export default JivoChatWidget;