import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../redux/selectors/index';

import parse from 'html-react-parser';

import Drift from 'react-drift-web'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';


import FreshChat from 'react-freshchat'

import JivoChatWidget from './JivoChatWidget'
import { Helmet, HelmetProvider } from 'react-helmet-async';



function LiveChat({history}){

    const site_settings = useSelector(allRemoteSettingsSelect);
    
    const useThisChat = site_settings.site_settings?.livechat_to_use ?? ''


    return (<div style={{backgroundColor: 'red', width: '20%'}}>

                {useThisChat == 0 && <TawkMessengerReact propertyId={site_settings.site_settings?.livechat_tawkto_property_id} widgetId={site_settings.site_settings?.livechat_tawkto_widget_id} />}

                {useThisChat == 1 && <Drift appId={site_settings.site_settings?.livechat_drift_id} />}
                
                {useThisChat == 2 && <Helmet>
                        <script
                        src='//fw-cdn.com/2497749/3055249.js'
                        chat='true'
                        widgetId={site_settings.site_settings?.livechat_fresh_chat_id}>
                        </script> }} />
                    </Helmet>}
                
                {useThisChat == 3 && <JivoChatWidget widgetId={site_settings.site_settings?.livechat_jivo_widget_id}/>}

            </div>
    );

}

export default LiveChat;