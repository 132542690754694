import React, { useState } from "react";
import { Link as Link2 } from "react-router-dom";

import AboutImage from "../assets/images/about.jpg";
import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

import parse from 'html-react-parser';
import {addUrlToPath} from '../utility/Helper1'


export default function About(props) {
  const [isOpen, setOpen] = useState(false)

  const text = props?.site_settings?.text_settings?.[0];
  const image = props?.site_settings?.image_settings?.[0];

  return (
    <>
      <section className="relative md:py-24 py-16 b dark:bg-slate-800" id="about" style={{backgroundColor: '#fff'}}>
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            

            <div className="lg:col-span-7">
              <div className="lg:ms-7">
                <h6 className="text-orange-600 text-base font-medium uppercase mb-2">{parse(text?.con30)}</h6>
                <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">{parse(text?.con31)}</h3>

                <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">{parse(text?.con32)}</p>

                <div className="relative mt-10">
                  <a href={`${props?.site_settings?.site_settings?.user_dashboard_base_url}/signup`} className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md">Get Started</a>
                </div>
              </div>
            </div>

            <div className="lg:col-span-5">
              <div className="relative">
                <img src={addUrlToPath(image?.image7)} className="rounded-lg shdow-lg relative" alt="" />
              </div>
            </div>
            {/* end col */}

            
          </div>
        </div>
      </section>
     
    </>
  );

}

