import React, { useState } from "react";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";
import ModalVideo from "react-modal-video";

import LogoLight from "../assets/images/logo-light.png";
import LogoDark from "../assets/images/logo-dark.png";

import BackgroudImage from "../assets/images/bg/5.jpg";
import About1 from "../assets/images/ab01.jpg";
import About2 from "../assets/images/ab02.jpg";

import Services from "../component/Services";
import About from "../component/About";
import AboutUs2 from "../component/About2";
import Pricing from "../component/Pricing";
import Blog from "../component/Blog";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Portfolio from "../component/Portfolio";
import Review from "../component/Testimonial";
import Switcher from "../component/Switcher";
import { useSelector, useDispatch } from 'react-redux';


import parse from 'html-react-parser';
import {addUrlToPath} from '../utility/Helper1'
import { allRemoteSettingsSelect } from '../redux/selectors/index';
import { Helmet } from 'react-helmet-async';
import './style.css';
import ChatDelegator from '../Livechats/ChatDelegator'


/**
 * Index eight 
 */
export default function IndexEight() {
    const [isOpen, setMenu] = useState(true);
    const [videoModal, setModal] = useState(false);

    window.addEventListener("scroll", windowScroll);

    /**
     * Window scroll
     */
    function windowScroll() {
        const navbar = document.getElementById("navbar");
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            navbar.classList.add("is-sticky");
        } else {
            navbar.classList.remove("is-sticky");
        }
    }
    const toggleMenu = () => {
        setMenu(!isOpen)
    }


    const site_settings = useSelector(allRemoteSettingsSelect);
    const texts_content = site_settings?.text_settings?.[0];
    const image_content = site_settings?.image_settings?.[0];

    const descToUse = site_settings?.site_settings?.description
    const titleToUse = site_settings?.site_settings?.title
    const siteName = site_settings?.site_settings?.siteName
    const favicon = addUrlToPath(site_settings?.site_settings?.favicon)
    const url = site_settings?.site_settings?.site_url



    const socials = [
            {
                title: 'facebook',
                uri: site_settings?.site_settings?.facebook,
                icon: 'uil-facebook-f',
            },
            {
                title: 'instagram',
                uri: site_settings?.site_settings?.instagram,
                icon: 'uil-instagram',
            },
            {
                title: 'twitter',
                uri: site_settings?.site_settings?.twitter,
                icon: 'uil-twitter',
            },
            {
                title: 'telegram',
                uri: site_settings?.site_settings?.telegram,
                icon: 'uil-telegram',
            },
            {
                title: 'whatsapp',
                uri: site_settings?.site_settings?.whatsapp,
                icon: 'uil-whatsapp',
            },

        ]

    return (
        <>

            
            <Helmet>

                <meta name="theme-color" content={'#161c2d'} />

                <title>{titleToUse}</title>
                <link rel="icon" type="image/x-icon" href={favicon} />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <meta name="description" content={descToUse} />

                <meta property="og:title" content={titleToUse} />
                <meta property="twitter:title" content={titleToUse} />
      
                <meta property="og:site_name" content={siteName} />
                <meta property="twitter:site" content={siteName} />

                <meta property="og:description" content={descToUse} />
                <meta property="twitter:description" content={descToUse} />
      
                <meta property="og:type" content="website" />

                <meta property="og:url" content={url} />

                <meta property="og:image" content={favicon} />
                <meta property="twitter:image" content={favicon} />

                <meta name="twitter:card" content="summary_large_image" />

            </Helmet>

            <div>
                <nav className="navbar bg-black" id="navbar">
                    <div className="container flex flex-wrap items-center justify-end">
                        <a className="navbar-brand" href="index.html">
                            <span>
                                <img style={{height: 40}} src={addUrlToPath(site_settings?.site_settings?.logo)} className="inline-block dark:hidden" alt="" />
                                <img style={{height: 40}} src={addUrlToPath(site_settings?.site_settings?.logo)} className="hidden dark:inline-block" alt="" />
                            </span>
                        </a>
                        <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
                            <ul className="list-none menu-social mb-0">
                                

                                {socials?.map((item, index) => {

                                    if(item.uri === ''){ return <></>; }

                                    return <li className="inline ms-1">
                                        <a href={item.uri} target="_blank" className="btn btn-sm btn-icon rounded-full bg-orang-600 hover:bg-orange-700 border-orang-600 hover:border-orange-700 text-white">
                                            <i className={`uil ${item.icon} align-middle`} title={item.title}></i>
                                        </a>
                                    </li>
                                })}


                            </ul>
                            <button
                                data-collapse="menu-collapse"
                                type="button"
                                className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden text-white"
                                onClick={toggleMenu}
                            >
                                <span className="sr-only">Navigation Menu</span>
                                <i className="mdi mdi-menu mdi-24px"></i>
                            </button>
                        </div>

                        <div
                            className={`${isOpen === true ? 'hidden' : 'block'} navigation lg_992:order-1 lg_992:flex`}
                            id="menu-collapse"
                        >
                            <ul className="navbar-nav" id="navbar-navlist">
                                <Link
                                    className="nav-item text-white"
                                    to="home"
                                    activeclassname="active"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                >
                                    <span className="nav-link text-white">Home</span>
                                </Link>



                                <Link
                                    className="nav-item"
                                    activeclassname="active"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    to="about"
                                >
                                    <span className="nav-link text-white">About us</span>
                                </Link>
                                
                                

                                <Link
                                    className="nav-item text-white"
                                    to="features"
                                    activeclassname="active"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                >
                                    <span className="nav-link text-white">Services</span>
                                </Link>


                                <Link
                                    className="nav-item text-white"
                                    activeclassname="active"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    to="about"
                                >
                                    <span className="nav-link text-white">Process</span>
                                </Link>



                                <a
                                    className="nav-item"
                                    href={`${site_settings?.site_settings?.user_dashboard_base_url}/signin`}
                                    activeclassname="active"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                >
                                    <span className="nav-link text-white">Login</span>
                                </a>


                                <a
                                    className="nav-item"
                                    href={`${site_settings?.site_settings?.user_dashboard_base_url}/signup`}
                                    activeclassname="active"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                >
                                    <span className="nav-link text-white">Register</span>
                                </a>
                                
                            </ul>
                        </div>
                    </div>
                </nav>


                <section
                    style={{ backgroundImage: `url(${addUrlToPath(image_content?.image1)})` }}

                    className="py-36 md:h-screen h-auto items-center flex relative bg-bottom bg-cover active" id="home">
                    <div className="absolute inset-0 -z-1 bg-gradient-to-b from-orange-600/20 dark:from-orange-600/40 via-orange-600/10 dark:via-orange-600/20 to-transparent"></div>
                    <div className="container relative">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                            <div className="lg:col-span-7 md:col-span-6 mt-14 md:mt-0">
                                <h4 style={{color: '#fff'}} className="lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative dark:text-white">
                                    {parse(texts_content?.con1)}
                                </h4>

                                <p className="text-slate-400 dark:text-white/70 mb-0 max-w-2xl text-lg">{parse(texts_content?.con2)}</p>

                                <div className="subcribe-form mt-10">


                                        <a href={`${site_settings?.site_settings?.user_dashboard_base_url}/signin`} className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-full">Login <i className="uil uil-arrow-right"></i></a>

                                </div>
                            </div>

                            {/*<div className="lg:col-span-5 md:col-span-6">
                                <div className="relative">
                                    <div className="relative flex" style={{alignItems: 'center', justifyContent: 'center'}}>
                                        <img src={addUrlToPath(image_content?.image1)} style={{width: 1200}} className="rounded-xl dark:shadow-gray-700" alt="" />
                                    </div>
                                </div>
                            </div>*/}
                            
                        </div>
                    </div>
                </section>


                {/* Service section */}
                <Services site_settings={site_settings} />

                {/* About section */}
                <About site_settings={site_settings} />


                {/* About section */}
                <AboutUs2 site_settings={site_settings} />

                

                {/* Pricing section */}
                {/*<Pricing />*/}


                {/* Review section */}
                {/*<Review />*/}
                {/* Contact section */}












                <footer className="new_footer_area bg_color">
                    <div className="new_footer_top">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <div className="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s">


                                        <h3 style={{marginTop: 10}} className="f-title f_600 t_color f_size_18">Company</h3>

                                        <Link to="#" className="logo-footer text-left">
                                            <img style={{height: 40}} src={addUrlToPath(site_settings?.site_settings?.logo)} className="md:ms-0 mx-auto" alt="" />
                                        </Link>
                                        <br />

                                        
                                        <p>
                                            {parse(site_settings?.text_settings?.[0]?.con32)}
                                        </p>
                                        
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s">
                                        <h3 style={{marginTop: 10}} className="f-title f_600 t_color f_size_18">Sitelink</h3>
                                        <ul className="list-unstyled f_list">

                                            <li>
                                                <Link
                                                    to="home"
                                                    activeclassname="active"
                                                    spy={true}
                                                    smooth={true}
                                                    duration={500}
                                                >
                                                    <span className="nk pointer" style={{cursor: 'pointer'}}>Home</span>
                                                </Link>
                                            </li>

                                            <li>
                                                <Link
                                                    activeclassname="active"
                                                    spy={true}
                                                    smooth={true}
                                                    duration={500}
                                                    to="about"
                                                >
                                                    <span className="nk pointer" style={{cursor: 'pointer'}}>About us</span>
                                                </Link>
                                            </li>
                                            

                                            <li>
                                                <Link
                                                    to="features"
                                                    activeclassname="active"
                                                    spy={true}
                                                    smooth={true}
                                                    duration={500}
                                                >
                                                    <span className="nnk pointer" style={{cursor: 'pointer'}}>Services</span>
                                                </Link>
                                            </li>

                                            <li>
                                                <Link
                                                    activeclassname="active"
                                                    spy={true}
                                                    smooth={true}
                                                    duration={500}
                                                    to="about"
                                                >
                                                    <span className="nk pointer" style={{cursor: 'pointer'}}>Process</span>
                                                </Link>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.6s">
                                        <h3 style={{marginTop: 10}} className="f-title f_600 t_color f_size_18">Help</h3>
                                        <ul className="list-unstyled f_list">
                                            <li>
                                                <a href={`${site_settings?.site_settings?.user_dashboard_base_url}/faq`}>FAQ</a>
                                            </li>
                                            <li>
                                                <a href={`${site_settings?.site_settings?.user_dashboard_base_url}/fund`}>Fund Wallet</a>
                                            </li>
                                            <li>
                                                <a href={`${site_settings?.site_settings?.user_dashboard_base_url}/loan`}>Loan Application</a>
                                            </li>
                                            <li>
                                                <a href={`${site_settings?.site_settings?.user_dashboard_base_url}/wallet`}>Wallet Balance</a>
                                            </li>
                                            <li>
                                                <a href={`${site_settings?.site_settings?.user_dashboard_base_url}/`}>Dashboard</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="f_widget social-widget pl_70 wow fadeInLeft" data-wow-delay="0.8s">
                                        <h3 style={{marginTop: 10}} className="f-title f_600 t_color f_size_18">Team Solutions</h3>
                                        <div className="f_social_icon">

                                            {socials?.map((item, index) => {

                                                if(item.uri === ''){ return <></>; }

                                                return <a href={item.uri} target="_blank" lassName="btn btn-icon btn-sm border rounded-md border-slate-700 dark:border-slate-800 hover:border-orange-600 bg-slate-800 dark:bg-gray-900 hover:bg-orange-600 dark:hover:bg-orange-600 text-gray-400 hover:text-white">
                                                        <i className={`uil ${item.icon}`} title={item.title}></i>
                                                    </a>
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer_bg">
                            <div className="footer_bg_one"></div>
                            <div className="footer_bg_two"></div>
                        </div>
                    </div>
                    
                </footer>










                {/* Footer section */}
                <Footer site_settings={site_settings}/>

                {/* Switcher section */}
                {/*<Switcher />*/}

                {(site_settings?.site_settings?.livechat_sitewide_floating_icon ?? 0) == 1 && <ChatDelegator />}

            </div>

        </>
    );

}
