import React from "react";
import { Link } from "react-router-dom";

import StrategyImage from "../assets/images/svg/design-thinking.svg";
import departmentImage from "../assets/images/svg/coding.svg";
import launchImage from "../assets/images/svg/office-desk.svg";

import parse from 'html-react-parser';
import {addUrlToPath} from '../utility/Helper1'


export default function Services(props) {

  const text = props?.site_settings?.text_settings?.[0];
  const image = props?.site_settings?.image_settings?.[0];

  const team = [
    {
      title: parse(text?.con6),
      icon: 'adjust-circle',
      subtext: parse(text?.con7)
    },
    {
      title: parse(text?.con8),
      icon: 'circuit',
      subtext: parse(text?.con9)
    },
    {
      title: parse(text?.con10),
      icon: 'fire',
      subtext: parse(text?.con11)
    },
    {
      title: parse(text?.con12),
      icon: 'shopping-basket',
      subtext: parse(text?.con13)
    },
    {
      title: parse(text?.con14),
      icon: 'flower',
      subtext: parse(text?.con15)
    },
    {
      title: parse(text?.con16),
      icon: 'flip-h',
      subtext: parse(text?.con17)
    }
  ]


  return (
    <>
      {/* Start */}
      <section className="relative md:py-24 py-16 active" id="features">
        


        <div className="container lg mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 pb-8 items-center">
            <div>
              <h6 className="text-orange-600 text-base font-medium uppercase mb-2">{parse(text?.con3)}</h6>
              <h3 className="mb-4 md:text-2xl text-xl font-semibold dark:text-white md:mb-0">{parse(text?.con4)}</h3>
            </div>

            <div>
              <p className="text-slate-400 dark:text-slate-300 max-w-xl">{parse(text?.con5)}</p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">

            {team.map((item, key) => (
              <div key={key} className={`features p-6 ${key % 2 === 0 ? "hover:shadow-xl hover:shadow-slate-100 dark:hover:shadow-slate-800" : "shadow-xl shadow-slate-100 dark:shadow-slate-800"} transition duration-500 rounded-3xl mt-8`}>
                <div className="w-20 h-20 bg-orange-600/5 text-orange-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                  <i className={`uil uil-${item.icon}`}></i>
                </div>

                <div className="content mt-7">
                  <Link to="#" className="text-lg hover:text-orange-600 dark:text-white dark:hover:text-orange-600 transition-all duration-500 ease-in-out font-medium">{item.title}</Link>
                  <p className="text-slate-400 mt-3">{item.subtext}</p>
                </div>
              </div>
            ))}
          </div>
        </div>




        <div className="container md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-orange-600 text-base font-medium uppercase mb-2">{parse(text?.con18)}</h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">{parse(text?.con19)}</h3>

            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">{parse(text?.con20)}</p>
          </div>

          <div className="grid grid-cols-1 mt-8">
            <div className="timeline relative">
              <div className="timeline-item">
                <div className="grid sm:grid-cols-2">
                  <div className="">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={addUrlToPath(image?.image3)} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 md:mt-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">{parse(text?.con21 ?? '')}</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">{parse(text?.con22 ?? '')}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="md:order-1 order-2">
                    <div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">{parse(text?.con23 ?? '')}</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">{parse(text?.con24 ?? '')}</p>
                    </div>
                  </div>
                  <div className="md:order-2 order-1">
                    <div className="duration duration-right md:ms-7 relative">
                      <img src={addUrlToPath(image?.image4)} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="mt-4 mt-sm-0">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={addUrlToPath(image?.image5)} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 mt-sm-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">{parse(text?.con25 ?? '')}</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">{parse(text?.con26 ?? '')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



      </section>
    </>
  );

}
