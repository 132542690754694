import React from "react";
import { Link } from "react-router-dom";

import LogoLight from "../assets/images/logo-light.png";

import parse from 'html-react-parser';
import {addUrlToPath} from '../utility/Helper1'

export default function Footer(props) {

    const socials = [
            {
                title: 'facebook',
                uri: props.site_settings?.site_settings?.facebook,
                icon: 'uil-facebook-f',
            },
            {
                title: 'instagram',
                uri: props.site_settings?.site_settings?.instagram,
                icon: 'uil-instagram',
            },
            {
                title: 'twitter',
                uri: props.site_settings?.site_settings?.twitter,
                icon: 'uil-twitter',
            },
            {
                title: 'telegram',
                uri: props.site_settings?.site_settings?.telegram,
                icon: 'uil-telegram',
            },
            {
                title: 'whatsapp',
                uri: props.site_settings?.site_settings?.whatsapp,
                icon: 'uil-whatsapp',
            },

        ]




    return (
        <>
            {/*  Start Footer  */}
            <footer className="py-8 bg-slate-800 dark:bg-gray-900">
                <div className="container">
                    <div className="grid md:grid-cols-12 items-center">
                        <div className="md:col-span-3">
                            
                        </div>

                        <div className="md:col-span-5 md:mt-0 mt-8">
                            <div className="text-center">
                                <p className="text-gray-400">© {(new Date().getFullYear())}{" "} {props.site_settings?.site_settings?.sitename}.</p>
                            </div>
                        </div>

                        <div className="md:col-span-4 md:mt-0 mt-8">
                            <ul className="list-none foot-icon md:text-end text-center">

                                

                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!-- End Footer --> */}

            {/* <!-- Back to top --> */}
            <Link to="home" id="back-to-top" className="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-orange-600 text-white leading-9"><i className="uil uil-arrow-up"></i></Link>
            {/* <!-- Back to top --> */}
        </>
    );

}

